/* #CircleCheckout {
  padding-top: 0;
}

#CircleCheckout .pb-4\.5 {
  display: none !important;
} */

#CircleCheckout .cb-card,
#CircleCheckout .cw-card {
  background-color: #47454f;
}

#CircleCheckout .cb-card button h1 {
  color: #b0b1a9 !important;
}

#CircleCheckout h1 {
  color: #fff;
}

#CircleCheckout input {
  border-bottom: 1px solid rgb(44, 43, 48);
  border-radius: 0px;
}

#CircleCheckout input:focus {
  border-bottom: 1px solid #fff !important;
  color: #fff !important;
}

#CircleCheckout form label {
  font-weight: initial;
}

#CircleCheckout .pb-4\.5 h2 {
  display: none;
}

#CircleCheckout .text-primary-text-button {
  color: #010203 !important;
  font-weight: 400;
}

#CircleCheckout .cb-select,
#CircleCheckout .cw-select {
  border-bottom: 1px solid rgb(44, 43, 48);
  border-radius: 0;
}

#CircleCheckout .cb-select .select,
#CircleCheckout .cw-select .select {
  background-color: #000 !important;
}

#CircleCheckout h2 img {
  display: none !important;
}

#CircleCheckout .pb-4\.5 span {
  display: none;
}

/* #CircleCheckout .pb-4\.5~div~div>div>.px-7 {
  display: none;
} */


#checkout-with-card-iframe {
  border: none;
}
